@use '@styles/mixins.scss';

.menu-contents {
  @include mixins.flex-col;
  position: absolute;
  background-color: #fff;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  gap: 20px;
  padding: 1.5rem 2.7rem 1.5rem 1.5rem;
  z-index: 100;

  .menu-item {
    @include mixins.selectable;
    box-sizing: border-box;
    // padding: 1rem 3.5rem 1rem 1.5rem;
    &.disabled {
      cursor: no-drop;
    }
  }
}
