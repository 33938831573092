.footer {
    background-color: #fff;
    color: #131214;
    padding: 0px 20px;
    border-top: 1px solid #E9EAEB;
    font-size: 12px;
    z-index: 1;
    position: relative;
  
    .footer-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .footer-copyright {
      font-size: 12px;
    }
  
    .footer-links {
      a, .manage-preferences {
        color: #131214;
        text-decoration: none;
        margin: 0 5px;
        background: none;
        border: none;
        font: inherit;
        cursor: pointer;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  