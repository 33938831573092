
.wellbeing-result {
    max-width: 850px;
    padding: 20px;
    margin: auto;

    &__back-button {
      cursor: pointer;
    }

    &__container {
      display: flex;
      margin-bottom: 20px;
      justify-content: space-between;
    }

    &__view-results{
      display: flex;
      gap:3px;
      align-items: center;
      cursor: pointer;
    }

  }
  
  .result {
    background: #FBFCFE;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid #DDE4F0;

    &__header {
      padding: 10px 14px;
      border-bottom: 1px solid #DDE4F0;
    }

    &__confidence {
      font-weight: 600;
      font-size: 1.125rem;
  
      &--high {
        color: #44BF6F;
      }
      &--moderate {
        color: #DFB447;
      }
      &--low {
        color: #D46262;
      }
    }
  
    &__details {
      display: flex;
      gap: 12px;
      padding: 10px;
    }
  
    &__emoji {
      border: 1px solid #E1E7EC;
      background-color: #F6F6FB;
      padding: 6px;
      border-radius: 6px;
      height: 38px;
    }
  } 