.chat-assignment {
    padding: 10px;

    &__card {
        background: #D1D4D6;
        color: #1A2631;
        padding: 10px;
        border-radius: 7px;
        width: 100%;
        max-width: 248px;
        position: relative;
    }
  
    &__header {
        display: flex;
        gap: 11px;
        margin-bottom: 17px;
    }
  
    &__icon {
      width: 45px;
      height: 45px;
      border-radius: 9px;
    }
  
    &__title {
        font-size: 0.875rem;
        font-weight: 600;
        font-family: 'Poppins';
    }
  
    &__date {
      font-size: 0.75rem;
      opacity: 0.7;
      font-weight: 400;
      font-family: 'Poppins';
      color: #48515A;
    }
  
  }
  