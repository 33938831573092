@use '../../../config/styles/mixins.scss';
@use '../../../config/styles/defaultStyle.scss' as default;

.header-section {
  @include mixins.flex-col;
  gap: 0.5rem;
  padding: 1.25rem 0;

  .left-headers {
    @include mixins.flex-col;
    align-items: flex-start;
    gap: 0.1rem;
    flex-grow: 1;
    margin-bottom: 0.5rem;
  }

  .filters-and-search {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
    flex-wrap: wrap;
  }

  &.provider-profile {
    @include mixins.flex-row;
    align-items: center;
    .left-headers {
      margin-bottom: 0;
    }
    .filters-and-search {
      justify-content: flex-end;
    }
  }
  .filters-collapsible-toggle {
    display: none;
    padding: 0.5rem 0;
    .title {
      color: #545353;
      font-weight: 600;
    }
    width: 100%;
  }
}

.location-disclaimer{
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  align-items: center;
}

/* media queries */

@media (max-width: 768px) {
  .header-section {
    padding-bottom: 0;
    .left-headers {
      @include mixins.flex-col;
      gap: 0.25rem;
      flex-grow: 1;
    }
    .filters-collapsible-toggle {
      @include mixins.flex-row;
      gap: 0.5rem;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
      border: 1px solid #dde4f0;
      margin: 0.5rem 0;

      &.open {
        background-color: #f1f2f5;
      }
      &.closed {
        background-color: #fbfcfe;
      }
    }
    .filters-and-search {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;

      &.open {
        max-height: auto;
        margin-bottom: 1rem;
      }
      &.closed {
        max-height: 0px;
        overflow: hidden;
        margin-bottom: 0;
      }

      @media (max-width: 425px) {
        grid-template-columns: 1fr;
      }
      .select-box,
      .multi-select-container {
        width: 100%;
        gap: 1rem;
      }
    }

    .location-dropdown {
      width: 100%;
      left: 0;
    }

    &.provider-profile {
      @include mixins.flex-col;
    }
  }
}
