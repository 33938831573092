.previous-results {
    padding: 20px;
    max-width: 850px;
    margin: auto;

    &__back-button {
      cursor: pointer;
    }

    &__result-item {
      background: #FBFCFE;
      padding: 16px 20px;
      margin-bottom: 12px;
      border-radius: 8px;
      cursor: pointer;
      transition: background 0.3s;
      display: flex;
      justify-content: space-between;
      border: 1px solid #DDE4F0;
    }

  }
  