.country-specific-v2-dropdown {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  z-index: 10;
  max-height: 300px;
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
  min-width: 180px;
}

.country-specific-v2-dropdown.shift-left {
  left:  calc(100% - 13.4rem);
  transform: none;
}

.country-specific-select-box {
  position: relative;
  display: inline-block;
  min-width: 132px;
}

.country-specific-placeholder-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  background: white;
  border: 1px solid #ccc;
  cursor: pointer;
  height: 44px;
}

.country-specific-placeholder-text {
  flex-grow: 1;
  text-align: left;
  padding-right: 4px;
}

.country-specific-dropdown-search {
  border: none;
  outline: none;
  margin-inline: 5px;
  font-size: 0.9rem;
  width: 90%;
  box-sizing: border-box;
}

.country-specific-dropdown-list {
  max-height: 250px;
  overflow-y: auto;
}

.country-specific-dropdown-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.country-specific-dropdown-item.selected {
  background-color: rgb(233, 236, 238);
}

.country-specific-dropdown-disclaimer {
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
}

.country-specific-no-results {
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.country-specific-disabled {
  background-color: #f3f3f3;
  color: #a1a1a1;
  border: 1px solid #d1d1d1;
  cursor: not-allowed;
}

.country-specific-disabled .country-specific-placeholder-text {
  color: #a1a1a1;
}

.country-specific-disabled .chevron {
  display: none;
}

.country-specific-dropdown-country {
  background-color: #F3F3F3A3;
  padding: 7px 9px;
}


.country-specific-dropdown-search-container {
  display: flex;
  align-items: center;
  gap: 2px;
  margin: 10px;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 6px;
  padding-left: 6px;

  .search-icon {
    width: 14px;
    height: 14px;
    opacity: 0.6;
  }

  .country-specific-dropdown-search {
    flex: 1;
    padding: 8px 0px;
    font-size: 0.9rem;
    border: none;
    outline: none;
    background: transparent;

    &::placeholder {
      color: #aaa;
    }
  }
}
