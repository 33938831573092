@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');

:root {
  --border-radius-btn: 2.4em;
  --btn-primary-color: #1A2631;
  --cross-btn: #898D8F;
  --cookie-category-bg: #ffffff;
  --cookie-category-border: #E9EAEB;
  --toggle-readonly-color: #48515A;
  --pm-btn-radius: 1.4rem;
}

body {
  margin: 0;
  box-sizing: border-box;
  background-color: #fff;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body div > * {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #e6edf2;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #8f9faa;
}

.react-tel-input {
  width: 100%;
  height: 44px;
  font-family: Poppins, sans-serif !important;

  input {
    border: 1px solid #d0d5dd;
    border-radius: 8px !important;
    font-family: Poppins, sans-serif !important;
    height: 100% !important;
    width: 100% !important;
  }
  .flag-dropdown,
  .selected-flag {
    border-radius: 8px 0 0 8px !important;
    background: #fff;
  }
  ul.country-list {
    height: 20rem;
  }
}

/* GDPR cookie consent modal styling */
#cc-main {
  --cc-btn-primary-bg: var(--btn-primary-color);
  --cc-btn-primary-border-color: var(--btn-primary-color);
  --cc-btn-secondary-bg: var(--cookie-category-bg);
  --cc-btn-secondary-border-color: var(--btn-primary-color);
  --cc-btn-secondary-color: var(--toggle-readonly-color);
  --cc-cookie-category-block-border: var(--cookie-category-border);
  --cc-cookie-category-block-bg: var(--cookie-category-bg);

  .cm__footer {
    background: none !important;
    padding: 0.9em 0 !important;
  }

  .cm--cloud {
    max-width: 50em;
    width: unset;

    .cm__desc {
      max-height: 10.4em !important;
    }

    .cm__btn {
      min-width: 25em !important;
      border-radius: var(--border-radius-btn);
    }
  }

  .cm__btns {
    border-left: none !important;
  }

  .cm__desc, .cm__title {
    padding-left: 1.3rem !important;
  }

  .cm__desc {
    padding-bottom: 1.3rem !important;
    overflow-y: hidden !important;
  }

  .cm__title + .cm__desc {
    margin-top: 0 !important;
  }

  .pm__title {
    font-weight: 700 !important;
    font-size: larger !important;
  }

  .pm__header {
    padding: 0.5em 1.4em !important; 
  }

  .pm__close-btn {
    background: none !important;
    border: none !important;

    svg {
      stroke: var(--cross-btn) !important;
    }
  }

  .pm__body {
    padding: 1.5em 1.4em !important;
  }

  .pm__section--expandable {
    .pm__section-arrow {
      background: none !important;

      svg {
        transform: scale(0.7) !important;
        stroke-width: 2px !important;
      }
    }
  }

  .pm__section--toggle .pm__section-title {
    font-weight: 300 !important;
  }

  .pm__btn {
    border-radius: var(--pm-btn-radius) !important;

    &:not(.pm__btn--secondary) {
      padding: 0 50px !important;
    }
  }
}

