$page-margin: 5vw;

.layout-container {
  // min-height: 100vh;
  background-color: #fff;
  height: 100vh;
  box-sizing: border-box;
  transition: background-color 0.3s ease;

  .main-content {
    min-height: calc(100vh - 80px);
    width: 90%;    
    margin: auto;
    box-sizing: border-box;

    &.no-page-margin {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .main-content:has(.onboarding-wrapper) {
    min-height: auto;
  }
}

.layout-container:has(.navbar-container.open) {
  overflow-y: hidden;
}

@media(max-width: 425px){
  .layout-container {
    .main-content{
      width: 100%;
      padding: 5px;
      margin: 0;
    }
  }
}

.layout-container.screener-bg {
  background-color: #FFF7EB;

  .header-container {
    background-color: #fff;
  }
}
