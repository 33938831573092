.chat-message-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  position: relative;

  &.outbound {
    justify-content: flex-end;
  }

  &.inbound {
    justify-content: flex-start;
  }

  .chat-contents {
    max-width: 75%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    gap: 5px;
    white-space: pre-wrap;
    padding: 5px;
    .msg-timestamp{
      display: flex;
      width: 100%;
      justify-content: flex-end;
      padding: 0 5px;
    }

    &.outbound {
      align-items: flex-end;
      background-color: #3c566f;
    }

    &.inbound {
      align-items: flex-start;
      background-color: #e9eaeb;
    }

    &.text {
      padding: 12px;
    }

    &.media {
      padding: 0px;
    }

    &.assignment {
      border-radius: 16px;
    }
  }
}

.chat-assignment-greeting {
    font-size: 0.75rem;
    font-family: 'Poppins';
    font-weight: 400;
    padding: 12px !important; 
    width: 275px;
    color: #48515A;
}
