.screener {
    display: flex;
    height: calc(100vh - 10vh);
    align-items: center;
    justify-content: center;

    &__marble {
        height: 75vh;
        width: 95vw;
        border-radius: 0.5rem;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border: none !important;

        @media (min-width: 1024px) {
            width: 60vw;
        }
    }
}