.onsite-event-home {
  width: 100%;
  padding-bottom: 20px;
  
  .header-onsite {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem 1rem 0;
  }
  .grid-container {
    display: flex;
    flex-wrap: wrap;
    gap: 28px;
    margin: auto;
    width: 100%;
  }
}
