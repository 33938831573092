@use '@styles/mixins.scss';

.session-cancel-container {
  @include mixins.flex-col;
  position: relative;
  height: 50vh;
  width: 50vw;

  .notes-container {
    width: 100%;
    height: 100%;
    margin-top: 1.5rem;
    margin-bottom: 5rem;
  }

  .cancel-action {
    @include mixins.flex-row(center, center);
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}

 @mixin warning-actions() {
  .warning-actions {
    @include mixins.flex-col(center, center);
    gap: 20px;
    width: 100%;
    .button-container {
      width: 100%;
    }
  }
 }

 @mixin confirmation-modal($justify-content:center, $align-items:center, $width: 500px) {
  width: clamp($width, $width, 35vw);
  @include mixins.flex-col($justify-content, $align-items);
  .success-icon {
    height: 200px;
    width: 238px;
  }
  .broken-icon {
    height: 165px;
    width: 130px;
  }
 }
.cancelled-confirmed-session-wrapper {
  .cancellation-warning-modal {
    height: clamp(313px, 313px, 40vh);
    @include mixins.flex-col(center, center);
    text-align: center;
    gap: 4rem;
    padding: 2rem 1.5rem 1.5rem 1.5rem;
    @include warning-actions();
   
  }

  .cancellation-confirmation {
    @include confirmation-modal();
    padding: 1.5rem;
    gap: 1rem;
    .sub-text {
      margin: 0 0 1.2rem 0;
    }
    .action-handler {
      width: 100%;
      .button-container {
        width: 100%;
      }
    }
  }

  .within-24hrs {
    @include confirmation-modal(center, center, 490px);
    padding: 1rem;
    gap: 0.1rem;
    div {
      margin: 1rem 0 0 0;
      &:nth-child(2) {
        margin: 0 0 2.1rem 0;
      }
    }
    @include warning-actions();
  }

  .cancellation-reasons-modal {
    @include confirmation-modal(flex-start, center, 100%);
    height: 100%;
    overflow: hidden;
    &.loader {
      height: 595px;
      width: 530px;
    }
    .reasons {
      display: grid;
      grid-template-columns: 1fr;
      height: 290px;
      overflow-y: auto;
      place-content: flex-start;
      scroll-behavior: smooth;
      gap: 24px;
      padding-bottom: 20px;
      width: 100%;
      margin: 1.9rem 0;
      .card-container {
        padding: 1rem 1.5rem;
      }
    }
    .other-reasons {
      border: none;
      border-bottom: 1px solid rgba(208, 213, 221, 1);;
      font-size: 14px;
      font-weight: 400;
      height: 30px;
      overflow-y: auto;
      margin: 10px 0 10px 10px;
      padding-left: 10px;
      font-family: 'Poppins';
      resize: none;
      &:focus{
        outline: none;
      }
    }
    &__header {
      display: flex;
      justify-content: flex-start;
      width: 90%;
      align-self: self-start;
    }
    .action-handler {
      width: 95%;
      display: flex;
      height: 100%;
      margin: 16px 0 8px 0;
      .button-container {
        width: 100%;
      }
    }
  }
}
.confirmed-cancellation-popup {
  .popup-container {
    border-radius: 16px;
  }
  .close-icon {
    top: 20px;
    right: 26px;
  }
}

@media (max-width: 1000px) {
  .confirmed-cancellation-popup {
    width: 60%;
  }
  .success-icon {
    height: 50px;
    width: 50px;
  }
  .cancellation-confirmation, .within-24hrs, .cancellation-reasons-modal {
    width: 100% !important;
  }
}

@media (max-width: 600px) {
  .confirmed-cancellation-popup {
    width: 80%;
  }
}