.insights-container {
    max-width: 850px;
    margin: auto;
    padding: 20px;
  }
  
  // Header Section
  .insights-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f5f5f5;
    padding: 20px 20px 0px 20px;
    border-radius: 8px;
    margin: 10px 0px 50px 0px;
    background-color: #FFF7EB;
    border: 1px solid #FDECD3;
  
    .header-text {
      flex: 1;
      padding-bottom: 20px;

      .header-badge {
        background-color: #FFEACB;
        width: 48px;
        text-align: center;
        padding: 4px;
        border-radius: 5px;
        margin-bottom: 6px;
      }
  
      .start-button {
        background: #007bff;
        color: white;
        border: none;
        padding: 10px 15px;
        cursor: pointer;
        transition: background 0.3s;
        margin-top: 16px;
        background-color: #263E58;
        border-radius: 20px;
        font-weight: 600;
        font-size: 0.875rem;
  
        &:hover {
          background: #072749;
        }
      }
    }
  
    .header-image {
      width: 150px;
      img {
        max-width: 100%;
        height: auto;
        margin-bottom: -50px;
        clip-path: inset(0px 0px 7px 0px);       
      }
    }
  }
  
  // Past Results Section
  .past-results {
    margin-bottom: 40px;

    .results-header{
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      .view-results{
        display: flex;
        gap:3px;
        align-items: center;
        cursor: pointer;
      }
    }
  
    .results-list {
      .result-item {
        background: #FBFCFE;
        padding: 16px 20px;
        margin-bottom: 12px;
        border-radius: 8px;
        cursor: pointer;
        transition: background 0.3s;
        display: flex;
        justify-content: space-between;
        border: 1px solid #DDE4F0;
      }
    }
  }
  
  // FAQ Section
  .faq-section {
    margin-top: 20px;
  
    .query-email {
      text-decoration: underline;
      color: #1570EF;
    }

    .link {
      cursor: pointer;
    }
  
    .faq-list {
      .faq-item {
        border-bottom: 1px solid #DDE4F0;
        padding: 20px 10px;
  
        .faq-question {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
        }
  
        .faq-answer {
          margin-top: 15px;
          color: #636C73;
          font-size: 0.75rem;
          font-weight: 400;

          li::marker {
            font-size: 0.7em;
          }
        }
      }
    }
  }
  