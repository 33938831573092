.banner {
    background: #FFF7EB;
    padding: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    position: relative;
    width: 100%;
    text-align: center;
  }
  
  .banner-content {
    display: flex;
    align-items: center;
    gap: 35px;
    width: 80%;
    justify-content: center;
  }
  
  .banner-text {
    font-size: 0.8rem;
    color: #292D32;
    text-align: center;
    font-weight: 500;
  }
  
  .banner-button {
    background: #263E58;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 500;
  }
  
  .banner-button:hover {
    background: #334155;
  }
  
  .banner-close {
    position: absolute;
    right: 22px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  