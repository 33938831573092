.disclaimer-card-container {
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
  
    &.info {
      border: 1px solid #FEDDAD;
      background: #FFF7EB;
    }
  
    .content {
      display: flex;
      flex-direction: column;
  
      ul {
        margin: 0;
        padding-left: 20px;
      }

      ul li::marker {
        font-size: 0.7em;
      }
  
      li {
        list-style: disc;
      }
    }
  }

  .disclaimer-link {
    color: #388BF2;
  }

  .disclaimer-action-button {
    background: none;
    border: none;
    color: #388bf2;
    font-size: 0.7rem;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
    margin-left: auto;
  }