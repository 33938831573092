@use '../../../config/styles/defaultStyle.scss' as default;

.login-container {
  height: calc(100vh - 50px);
  width: 100%;
  display: flex;
  flex-direction: row;

  .action-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 2;
    align-items: flex-start;

    .logo-container {
      margin-left: 15%;
      margin-top: 15%;
    }

    .login-form {
      //   align-self: center;
      margin-top: 10%;
      width: 50%;
      margin-left: 13%;

      .login-header {
        margin: 5px 0;
      }

      .form-container {
        margin-top: 60px;
        width: 400px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        gap: 20px;
      }
    }
  }

  .intro-container {
    width: 50%;
    background-color: default.$primary-color;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .circle-fade {
      position: absolute;
      overflow: hidden;
      border: 10vw solid #f5e5e5;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      top: -60%;
      right: 35%;
      opacity: 0.05;
    }

    .content-container {
      margin-top: 22%;
      margin-left: 25%;
      width: 50%;

      .quote-icon {
        margin-bottom: 5px;
      }

      .intro {
        text-align: left;
        width: 80%;
      }

      img.right-angle {
        margin-left: 15vw;
        // margin-top: 2vw;
        position: absolute;
      }
    }

    .rect-arrow {
      position: absolute;
      background-image: url('../../../assets/rect-arrow.png');
      background-size: cover;
      background-repeat: no-repeat;
      width: 15%;
      height: 20%;
      bottom: 0;
      right: 0;
    }
  }
}

/* media queries */

@media (max-width: 1000px) {
  .login-container {
    .action-container {
      width: 100%;
      .login-form {
        width: 70%;
        .form-container {
          width: 100%;
        }
      }
    }
    .intro-container {
      display: none;
    }
  }

}
