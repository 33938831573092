.event-details-wrapper {
  padding: 16px 12px 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  box-sizing: border-box;
  overflow-y: auto;
  .event-header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .back-cta{
      cursor: pointer;
      transition: all 0.2s ease;
      &:hover{
        transform: scale(1.02);
      }
    }
  }
  .location-timing-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .location-timing {
      display: flex;
      .customized-gap {
        gap: 1rem;
      }
    }
  }

}

@media (max-width: 768px) {
  .event-details-wrapper {
    padding: 16px 0;
  }
}

@media (max-width: 575px) {
  .event-details-wrapper {
    .location-timing-wrapper {
      .location-timing {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
