

.warning-modal {
    width: 45%;
    top: 50%;

    @media(max-width: 1000px){
        width: 80%;
    }

    &__container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 25px;
        padding: 8px 3px;
    }

    &__header {
        width: 85%;
    }
    
    &__body{
        display: grid;
        gap: 1rem;
        width: 85%;
    }
}